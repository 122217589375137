<template>
  <v-swatches
    v-model="color"
    :disabled="disabled"
    :swatches="colors"
    row-length="6"
    shapes="circles"
    popover-x="left"
    @input="emitColor"
  >
    <v-select
      slot="trigger"
      readonly
      :disabled="disabled"
      :placeholder="$trans('color_in_calendar')"
      outlined
      class="color-select"
    >
      <v-icon slot="prepend-inner" :color="color" size="22"> $dot</v-icon>
    </v-select>
  </v-swatches>
</template>

<script>
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import employeeColors from "@/calendesk/tools/employeeColors";

export default {
  name: "ColorField",
  components: { VSwatches },
  props: {
    currentColor: {
      type: String,
      default: employeeColors.defaultEmployeeColor,
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      color: employeeColors.defaultEmployeeColor,
      colors: employeeColors.list.map((obj) => obj.background),
    };
  },
  watch: {
    currentColor: {
      handler(val) {
        this.color = val;
      },
      deep: true,
    },
  },
  methods: {
    emitColor(val) {
      this.$emit("color-value", val);
    },
  },
};
</script>
<style lang="scss">
.color-select label {
  // fixes label alignment
  left: -31px !important;
}
</style>
