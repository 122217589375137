<template>
  <v-container fluid>
    <v-row v-for="(item, index) in value" :key="index" no-gutters>
      <v-col class="d-flex align-center justify-start">
        <v-select
          :items="weekdays"
          hide-details
          :value="item.day"
          outlined
          dense
          full-width
          class="mr-1 day-select"
          :disabled="disabled"
          @change="weekdayChanged($event, index)"
        />
      </v-col>
      <v-col class="d-flex align-center justify-start">
        <div class="mr-1 my-2">
          <c-time-picker
            :disabled="disabled"
            :time="item.start_time"
            @update="item.start_time = $event"
          />
        </div>
        <c-time-picker
          :disabled="disabled"
          :time="item.end_time"
          :minimum-time="item.start_time"
          increase-minimum-time
          @update="item.end_time = $event"
        />
        <v-btn
          icon
          :disabled="disabled"
          small
          text
          class="mx-1 ml-1"
          @click.native="duplicate(index)"
        >
          <v-icon>$copy</v-icon>
        </v-btn>
        <v-btn
          icon
          :disabled="disabled"
          small
          text
          @click.native="remove(index)"
        >
          <v-icon>$trash-default</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-btn
          color="blue"
          dark
          outlined
          :disabled="disabled"
          @click.native="add"
        >
          {{ $trans("add_new_day") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CTimePicker from "@/components/CSchedule/CTimePicker";

export default {
  components: { CTimePicker },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      weekdays: [
        {
          text: this.$trans("monday"),
          value: "monday",
        },
        {
          text: this.$trans("tuesday"),
          value: "tuesday",
        },
        {
          text: this.$trans("wednesday"),
          value: "wednesday",
        },
        {
          text: this.$trans("thursday"),
          value: "thursday",
        },
        {
          text: this.$trans("friday"),
          value: "friday",
        },
        {
          text: this.$trans("saturday"),
          value: "saturday",
        },
        {
          text: this.$trans("sunday"),
          value: "sunday",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
    }),
  },
  methods: {
    remove(removeIndex) {
      const value = this.value.filter((item, index) => index !== removeIndex);
      this.$emit("change", value);
    },
    duplicate(duplicateIndex) {
      const value = this.$helpers.cloneObject(this.value);
      const clone = this.value.find((item, index) => index === duplicateIndex);

      value.splice(duplicateIndex + 1, 0, {
        start_time: clone.start_time,
        end_time: clone.end_time,
        day: clone.day,
      });

      this.$emit("change", value);
    },
    weekdayChanged(day, index) {
      const value = this.$helpers.cloneObject(this.value);
      value[index].day = day;
      this.$emit("change", value);
    },
    add() {
      const value = this.$helpers.cloneObject(this.value);
      value.push({
        start_time: this.configuration.calendar_time.from,
        end_time: this.configuration.calendar_time.to,
        day: "monday",
      });
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.day-select {
  width: 100%;
}
</style>
