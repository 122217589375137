<template>
  <v-form ref="EmployeeForm" class="employee-form" @submit.prevent="handleSave">
    <v-container class="form__container pt-6" fluid>
      <v-row>
        <v-col cols="12">
          <h6 class="text-h6" v-text="$trans('basic_data')" />
        </v-col>
      </v-row>
      <v-row class="pt-0 mt-0">
        <v-col cols="12" md="4">
          <calendesk-image-select
            v-model="image"
            :disabled="isLoadingData"
            avatar
            :select-image-button-text="$trans('select')"
            :remove-image-button-text="$trans('remove')"
            @input="setAvatar"
          />
        </v-col>
        <v-col cols="12" md="8">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.name"
                  :disabled="isLoadingData"
                  :label="$trans('name')"
                  :rules="[rules.required, rules.maxChars(50)]"
                  outlined
                  class="required-star"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.surname"
                  :disabled="isLoadingData"
                  :label="$trans('surname')"
                  :rules="[rules.required, rules.maxChars(50)]"
                  outlined
                  class="required-star"
                />
              </v-col>
              <v-col cols="12" md="4">
                <color-field
                  :disabled="isLoadingData"
                  :current-color="form.color"
                  @color-value="form.color = $event"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.email"
                  :disabled="
                    isLoadingData ||
                    (selectedUserIsAdmin && !loggedUserIsAdmin) ||
                    (selectedUserIsRoot && !loggedUserIsRoot)
                  "
                  :label="$trans('email')"
                  :rules="
                    form.email
                      ? [rules.email, rules.maxChars(60)]
                      : selectedUserIsAdmin
                      ? [rules.required]
                      : []
                  "
                  :error-messages="apiErrors.email"
                  :class="{
                    'required-star': selectedUserIsAdmin,
                  }"
                  validate-on-blur
                  outlined
                />
              </v-col>
              <v-col cols="12" md="6">
                <phone-input
                  v-model="form.default_phone"
                  :rules="selectedUserIsRoot ? [rules.required] : []"
                  :disabled="isLoadingData"
                  :default-country-code="
                    $config('preferred_phone_countries')[0]
                  "
                  @enter="handleSave"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="isPublic"
                  :disabled="isLoadingData"
                  :label="$trans('employee_public_checkbox')"
                  class="mt-0 pt-0"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="loggedUserCanDeletePermissions" md="6" cols="12">
          <v-container class="pl-0 ml-0">
            <v-row>
              <v-col>
                <h6 class="text-h6" v-text="$trans('permissions')" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <calendesk-information-message>
                  {{ $trans("employee_permission_info") }}
                </calendesk-information-message>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 mt-0">
                <v-checkbox
                  v-model="form.admin_access"
                  :disabled="
                    isLoadingData || selectedUserIsRoot || !loggedUserIsAdmin
                  "
                  :label="$trans('admin_access_question')"
                  class="pt-0 mt-0"
                />
              </v-col>
            </v-row>
            <v-row v-if="form.admin_access">
              <v-col>
                <calendesk-information-message>
                  <strong>{{
                    $trans("employee_permission_employee_basic")
                  }}</strong>
                  - {{ $trans("employee_permission_employee_basic_info")
                  }}<br /><br />
                  <strong>{{ $trans("employee_permission_employee") }}</strong>
                  - {{ $trans("employee_permission_employee_info")
                  }}<br /><br />
                  <strong>{{ $trans("employee_permission_manager") }}</strong> -
                  {{ $trans("employee_permission_manager_info") }}<br /><br />
                  <strong>{{ $trans("employee_permission_admin") }}</strong> -
                  {{ $trans("employee_permission_admin_info") }}
                </calendesk-information-message>
              </v-col>
            </v-row>
            <v-row v-if="form.admin_access">
              <v-col>
                <roles-field
                  v-if="!selectedUserIsRoot"
                  ref="roles-field"
                  :disabled="isLoadingData || !loggedUserIsAdmin"
                  :value="roleId"
                  @change="roleId = $event"
                />
                <v-text-field
                  v-else
                  :value="$trans('role_admin')"
                  disabled
                  :label="$trans('choose_role')"
                  outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col v-if="loggedUserCanEditServices" md="6" cols="12">
          <v-container class="pl-0 ml-0">
            <v-row>
              <v-col>
                <h6
                  class="text-h6"
                  v-text="$trans('employee_assigned_to_services_title')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <calendesk-information-message>
                  {{ $trans("employee_assigned_to_services_info") }}
                </calendesk-information-message>
              </v-col>
              <v-col cols="12">
                <services-field
                  v-model="selectedServiceIds"
                  small-chips
                  multiple
                  deletable-chips
                  return-ids
                  :label="$trans('services')"
                  keep-menu-open-on-select
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h6 class="text-h6" v-text="$trans('employee_working_hours')" />
              </v-col>
            </v-row>
            <v-row v-if="showOldEmployeesWorkScheduleCheckbox">
              <v-col cols="12" class="my-0">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-checkbox
                      v-model="useEmployeesWorkSchedules"
                      hide-details
                    >
                      <template #label>
                        <div class="d-flex align-center flex-wrap" v-on="on">
                          <v-icon color="orange" class="mr-2"> $alert</v-icon>
                          {{ $trans("old_employee_schedule_label") }}
                        </div>
                      </template>
                    </v-checkbox>
                  </template>
                  {{ $trans("old_employee_schedule_label_tooltip") }}
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <calendesk-information-message>
                  {{ $trans("employee_working_hours_info") }}
                </calendesk-information-message>
              </v-col>
            </v-row>
            <v-row v-if="useEmployeesWorkSchedules">
              <v-col cols="12" class="pa-0">
                <employee-availability-picker v-model="form.days" />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                <availability-schedule
                  v-model="form.availability_schedule_id"
                  :disabled="isLoadingData"
                />
              </v-col>
            </v-row>
            <template
              v-if="
                $adminConfig('app_employees_time_zone_enabled') ||
                $adminConfig('app_employees_locale_enabled') ||
                form.locale ||
                form.time_zone
              "
            >
              <v-row>
                <v-col>
                  <h6 class="text-h6" v-text="$trans('user_time_zone_title')" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <calendesk-information-message>
                    {{ $trans("user_time_zone_locale_info") }}
                  </calendesk-information-message>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <c-language-select
                    v-model="form.locale"
                    :label="$trans('c_language_select_label')"
                    :disabled="
                      isLoadingData ||
                      !$adminConfig('app_employees_locale_enabled')
                    "
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <c-time-zone-autocomplete
                    v-model="form.time_zone"
                    :label="$trans('c_time_zone_autocomplete_label')"
                    :disabled="
                      isLoadingData ||
                      !$adminConfig('app_employees_time_zone_enabled')
                    "
                  />
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-if="loggedUserCanEditEmployees"
      class="form__container"
      fluid
    >
      <v-row>
        <v-col>
          <v-checkbox
            v-model="showAdvancedOptions"
            :disabled="isLoadingData"
            :label="$trans('public_data')"
          />
        </v-col>
      </v-row>
      <template v-if="isPublic && showAdvancedOptions">
        <v-row>
          <v-col>
            <calendesk-information-message>
              {{ $trans("employee_public_info") }}
            </calendesk-information-message>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="form.title"
                  :disabled="isLoadingData"
                  :label="$trans('employee_position')"
                  :placeholder="$trans('employee_position_example')"
                  outlined
                  :rules="[rules.maxChars(100)]"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form.description"
                  :disabled="isLoadingData"
                  :placeholder="$trans('employee_note_description')"
                  :label="$trans('description')"
                  outlined
                  :rules="[rules.maxChars(1000)]"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.facebook"
                  outlined
                  :disabled="isLoadingData"
                  :label="$trans('facebook')"
                  placeholder="https://facebook.com/"
                  :rules="form.facebook ? [rules.url, rules.maxChars(255)] : []"
                  validate-on-blur
                  hide-details="auto"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.twitter"
                  outlined
                  :disabled="isLoadingData"
                  :label="$trans('twitter')"
                  placeholder="https://twitter.com/"
                  :rules="form.twitter ? [rules.url, rules.maxChars(255)] : []"
                  validate-on-blur
                  hide-details="auto"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.instagram"
                  outlined
                  :disabled="isLoadingData"
                  :label="$trans('instagram')"
                  placeholder="https://instagram.com/"
                  :rules="
                    form.instagram ? [rules.url, rules.maxChars(255)] : []
                  "
                  validate-on-blur
                  hide-details="auto"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.youtube"
                  outlined
                  :disabled="isLoadingData"
                  :label="$trans('youtube')"
                  placeholder="https://youtube.com/"
                  :rules="form.youtube ? [rules.url, rules.maxChars(255)] : []"
                  validate-on-blur
                  hide-details="auto"
                  dense
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-container>
    <v-container
      v-if="!isEdit && form.email"
      fluid
      class="form__container snow_gray"
    >
      <v-row justify="start">
        <v-col md="12" class="checkbox-align">
          <v-checkbox
            v-model="sendWelcomeEmail"
            :disabled="isLoadingData"
            :label="$trans('send_email_with_password_change')"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="form__container">
      <v-row>
        <v-col class="text--secondary">
          {{ $trans("fields_required") }}
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col>
          <v-btn
            :disabled="isLoadingData"
            :loading="isLoadingData"
            color="primary"
            type="submit"
          >
            <span v-text="selectedUser ? $trans('save') : $trans('add')" />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import PhoneInput from "@/components/Forms/PhoneInput/PhoneInput";
import {
  email,
  maxChars,
  minChars,
  required,
  url,
} from "@/lib/calendesk-js-library/forms/validators";
import ColorField from "@/components/Forms/ColorField";
import CalendeskImageSelect from "@/lib/calendesk-js-library/components/CalendeskImageSelect";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import EmployeeAvailabilityPicker from "@/components/CSchedule/EmployeeAvailabilityPicker";
import roleActions from "@/calendesk/mixins/roleActions";
import AvailabilitySchedule from "@/components/AvailabilitySchedule";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import ServicesField from "@/lib/calendesk-js-library/components/ServicesField.vue";
import CTimeZoneAutocomplete from "@/views/dashboard/pages/Settings/components/CTimeZoneAutocomplete.vue";
import CLanguageSelect from "@/views/dashboard/pages/Settings/components/CLanguageSelect.vue";

export default {
  name: "EmployeeFullForm",
  components: {
    CLanguageSelect,
    CTimeZoneAutocomplete,
    ServicesField,
    AvailabilitySchedule,
    EmployeeAvailabilityPicker,
    CalendeskInformationMessage,
    PhoneInput,
    CalendeskImageSelect,
    ColorField,
    RolesField: () => import("@/components/Forms/RolesField"),
  },
  mixins: [roleActions],
  data() {
    return {
      selectedServiceIds: null,
      useEmployeesWorkSchedules: false,
      showOldEmployeesWorkScheduleCheckbox: false,
      isLoadingData: false,
      apiErrors: {
        emailError: [],
      },
      showAdvancedOptions: false,
      rules: {
        required,
        email,
        minChars,
        maxChars,
        url,
      },
      form: {
        name: null,
        surname: null,
        email: null,
        default_phone: null,
        description: null,
        title: null,
        facebook: null,
        twitter: null,
        instagram: null,
        youtube: null,
        color: null,
        admin_access: false,
        days: [],
        availability_schedule_id: null,
        time_zone: null,
        locale: null,
      },
      sendWelcomeEmail: 1,
      sendEmployeeMobileAppLink: 0,
      isPublic: 1,
      image: null,
      imageUpdated: false,
      roleId: null,
    };
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
      selectedEmployee: "employee/getSelectedEmployee",
    }),
    isEdit() {
      return this.$route.name === "employee";
    },
    getRoleId() {
      if (this.selectedUser.roles.length > 0) {
        return this.selectedUser.roles[0].id;
      }
      return null;
    },
  },
  watch: {
    selectedUser(val) {
      if (val !== null) {
        this.fillForm();
      }
    },
  },
  created() {
    this.$nextTick(() => {
      document.documentElement.scrollTop = 0;
      this.fillForm();
    });
  },
  methods: {
    ...mapActions({
      updateEmployee: "employee/update",
      addEmployee: "employee/add",
      fetchStats: "dashboard/fetchStats",
      loadAuthUser: "setup/loadAuthUser",
      shouldRedirectAndFixUrl: "setup/shouldRedirectAndFixUrl",
    }),
    ...mapMutations({
      setSelectedUser: "user/SET_SELECTED_USER",
      setSelectedEmployee: "employee/SET_SELECTED_EMPLOYEE",
    }),
    setAvatar(image) {
      this.imageUpdated = true;
      this.image = image;
    },
    fillForm() {
      if (this.selectedEmployee && this.selectedUser) {
        this.form.id = this.selectedEmployee.id;
        this.form.name = this.selectedUser.name;
        this.form.surname = this.selectedUser.surname;
        this.form.email = this.selectedUser.email;
        this.form.default_phone = this.selectedUser.default_phone
          ? this.selectedUser.default_phone.e164
          : null;
        this.form.description = this.selectedEmployee.description;
        this.form.title = this.selectedEmployee.title;
        this.form.facebook = this.selectedEmployee.facebook;
        this.form.instagram = this.selectedEmployee.instagram;
        this.form.twitter = this.selectedEmployee.twitter;
        this.form.youtube = this.selectedEmployee.youtube;
        this.form.color = this.selectedEmployee.color;
        this.form.time_zone = this.selectedUser.time_zone;
        this.form.locale = this.selectedUser.locale;

        const services = this.selectedEmployee.services;

        if (services && services.length > 0) {
          this.selectedServiceIds = services.map((service) => service.id);
        }

        const days = this.$helpers.cloneObject(this.selectedEmployee.days);

        if (!this.selectedEmployee.availability_schedule_id && days) {
          this.useEmployeesWorkSchedules = days.length > 0;
          this.showOldEmployeesWorkScheduleCheckbox = days.length > 0;
          this.form.days = days.sort((a, b) => {
            const day1 = a.day.toLowerCase();
            const day2 = b.day.toLowerCase();
            return (
              this.$helpers.weekdaysSorter[day1] -
              this.$helpers.weekdaysSorter[day2]
            );
          });
        }

        this.form.availability_schedule_id =
          this.selectedEmployee.availability_schedule_id;
        this.isPublic = this.selectedEmployee.is_public;
        this.roleId = this.getRoleId;
        this.form.admin_access = this.roleId != null || this.selectedUserIsRoot;
        this.image = this.selectedUser.default_image;
        this.sendWelcomeEmail = 0;
        this.sendEmployeeMobileAppLink = 0;
      } else {
        this.form.days = this.workingHoursConvertToComponent(
          this.$helpers.cloneObject(this.configuration.company_working_hours)
        );
      }

      if (
        this.form.description ||
        this.form.title ||
        this.form.facebook ||
        this.form.instagram ||
        this.form.twitter ||
        this.form.youtube
      ) {
        this.showAdvancedOptions = true;
      }
    },
    workingHoursConvertToComponent(array) {
      const result = [];
      array
        .filter((item) => item.is_open)
        .forEach((item) => {
          const newItem = {};
          newItem.day = item.day;
          newItem.start_time = item.open;
          newItem.end_time = item.close;
          result.push(newItem);
        });
      return result;
    },
    handleSave() {
      if (this.$refs.EmployeeForm.validate()) {
        this.selectedUser ? this.update() : this.add();
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
    getFormData() {
      const data = this.$helpers.copyObj(this.form);
      data.send_employee_welcome_email = this.sendWelcomeEmail ? 1 : 0;
      data.send_employee_mobile_app_link = this.sendEmployeeMobileAppLink
        ? 1
        : 0;
      data.role_ids =
        this.roleId && this.form.admin_access ? [this.roleId] : [];
      data.is_public = this.isPublic ? 1 : 0;

      if (this.imageUpdated) {
        data.default_image_id = this.image ? this.image.id : null;
      }

      if (!this.useEmployeesWorkSchedules) {
        data.days = [];
      }

      data.services = this.selectedServiceIds ?? [];

      return data;
    },
    async update() {
      try {
        pushEvent("updateEmployee");

        this.isLoadingData = true;
        const oldLocale = this.loggedUser.locale;
        const oldTimezone = this.loggedUser.time_zone;

        const formData = this.getFormData();
        const response = await this.updateEmployee(formData);
        this.setSelectedEmployee(response);
        this.setSelectedUser(response.user);
        await this.clearCache("employee-list-v2");

        successNotification("update_success");
        this.sendingDone();

        if (this.loggedUser.id === response.user.id) {
          await this.loadAuthUser();

          if (oldLocale !== this.loggedUser.locale) {
            await this.shouldRedirectAndFixUrl(this.loggedUser.locale);
          } else if (oldTimezone !== this.loggedUser.time_zone) {
            location.reload();
          }
        }

        this.$router.push({ name: "employees" });
      } catch (error) {
        this.showErrorMessage(error);
      }
    },
    async add() {
      try {
        pushEvent("createEmployee");

        this.isLoadingData = true;
        const response = await this.addEmployee(this.getFormData());
        this.setSelectedEmployee(response);
        this.setSelectedUser(response.user);
        await this.fetchStats();
        successNotification("employee_created_successfully");
        this.sendingDone();
        this.clearCache("employee-list-v2");
        this.$router.push({ name: "employees" });
      } catch (error) {
        this.showErrorMessage(error);
      }
    },
    sendingDone() {
      this.isLoadingData = false;
      this.imageUpdated = false;
    },
    showErrorMessage(error) {
      this.sendingDone();
      let errorMessage = this.$trans("error_occurred");
      const emailIsTaken =
        this.$helpers.emailIsTakenError(error) ||
        (error.response && error.response.status === 409);

      if (emailIsTaken) {
        errorMessage = this.$trans("user_uses_email_already");
      }

      errorNotification(errorMessage, error, !emailIsTaken);
    },
  },
};
</script>
